/**
 * Send JavaScript error information to Google Analytics.
 * 
 * @param  {Window} window A reference to the "window".
 * @return {void}
 * @author Philippe Sawicki <https://github.com/philsawicki>
 */
(function (window) {
    // Retain a reference to the previous global error handler, in case it has been set:
    var originalWindowErrorCallback = window.onerror;

    /**
     * Log any script error to Google Analytics.
     *
     * Third-party scripts without CORS will only provide "Script Error." as an error message.
     * 
     * @param  {String}           errorMessage Error message.
     * @param  {String}           url          URL where error was raised.
     * @param  {Number}           lineNumber   Line number where error was raised.
     * @param  {Number|undefined} columnNumber Column number for the line where the error occurred.
     * @param  {Object|undefined} errorObject  Error Object.
     * @return {Boolean}                       When the function returns true, this prevents the 
     *                                         firing of the default event handler.
     */
    window.onerror = function (errorMessage, url, lineNumber, columnNumber, errorObject) {
        if (errorObject===null) {
            return
        }
        // In case the "errorObject" is available, use its data, else fallback 
        // on the default "errorMessage" provided:
        var exceptionDescription = errorMessage;
        if (typeof errorObject !== 'undefined' && typeof errorObject?.message !== 'undefined') {
            exceptionDescription = errorObject.message;
        }

        // Format the message to log to Analytics:
        StackTrace.fromError(errorObject).then(function (stack) {
            if (stack.length === 0 ||
                !stack[0].hasOwnProperty('fileName') ||
                !stack[0].hasOwnProperty('lineNumber') ||
                !stack[0].hasOwnProperty('columnNumber')) {
                return;
            }

            // Just send the file name not the path
            var shortFileName = stack[0].fileName.split("/").pop();
            var stackData = shortFileName + ':' + stack[0].lineNumber + ':' + stack[0].columnNumber;

            exceptionDescription += '@' + stackData;

            console.log(exceptionDescription)

            if (exceptionDescription.length > 75) {
                // If too long skip the error description & just send the filename:lineNumber:columnNumber
                exceptionDescription = stackData;
            }

            // Send error details to Google Analytics, if the library is already available:
            daz.help.event('Exception', {
                'exDescription': exceptionDescription, // exDescription max length 150 bytes
                'exFatal': false // Some Error types might be considered as fatal.
            });
        });


        // If the previous "window.onerror" callback can be called, pass it the data:
        if (typeof originalWindowErrorCallback === 'function') {
            return originalWindowErrorCallback(errorMessage, url, lineNumber, columnNumber, errorObject);
        }

        // Otherwise, Let the default handler run:
        return false;
    };
})(window)
